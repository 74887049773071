<template>
  <v-row id="main-page">
    <v-col id="nav-bar" cols="12" xl="2" lg="2" sm="4" md="3">
      <v-row>
        <v-col id="logo-container" cols="12">
          <img id="logo" src="@/assets/logo_rhea.png" alt="" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"></v-col>
      </v-row>
      <v-row id="nav-menu-container">
        <v-col
          v-for="menu in menus"
          @click="gotoUrl(menu.url)"
          v-bind:key="menu.id"
          class="main-menu-item"
          :class="{ active: menu.id == menuId }"
          lg="12"
          md="12"
          sm="12"
          xl="12"
          style="text-align: start"
          v-if="menu.authorizeStatus == true && menu.isLoading == false"
        >
          <v-row class="main-menu-item-row">
            <v-col cols="12" md="2" sm="2" lg="2" xl="2" style="text-align: center">
              <img
                :width="menu.size != null ? menu.size : '20px'"
                :src="'/assets/' + menu.icon"
              />
            </v-col>
            <v-col class="menu-title">
              {{ menu.title }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col id="nav-body" xl="10" lg="10" sm="8" md="9">
      <v-row>
        <v-col cols="12" align-self="start">
          <v-row id="nav-header">
            <v-col style="padding-bottom: 0px">
              <v-row>
                <v-col style="height: 60px" cols="12">
                  <v-text-field
                    id="search-box"
                    class="text-field"
                    flat
                    solo
                    v-model="searchKey"
                    label="Cari nomor permohonan"
                    append-icon="mdi-magnify"
                    variant="outlined"
                    @click:append="onClickSearch(searchKey)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-clock">
                  <v-label id="clock">{{ dateTimeStr }}</v-label>
                </v-col>
              </v-row>
            </v-col>
            <v-col id="user-info">
              <v-row>
                <v-col>
                  <LoadingSkeleton :isLoading="isLoading">
                    <v-col id="user-info-col" style="text-align: end; font-weight: bold">
                      <v-badge
                        id="menu-activator"
                        style="margin-right: 10px"
                        offset-x="10"
                        offset-y="10"
                        :content="isLoadingNotification ? '↻' : totalNotification"
                        :color="
                          totalNotification > 0 || isLoadingNotification
                            ? 'red'
                            : 'transparent'
                        "
                      >
                        <img src="/assets/icon-menu-notification.png" alt="" />
                      </v-badge>
                      <v-menu
                        v-model="isNotifOpened"
                        activator="#menu-activator"
                        :close-on-content-click="false"
                      >
                        <v-container
                          style="height: 400px; width: 100vw; max-width: 430px"
                        >
                          <v-container
                            style="
                              height: 90%;
                              overflow-y: scroll;
                              overflow-x: clip;
                              white-space: pre-wrap;
                              padding: 0px !important;
                            "
                          >
                            <v-progress-circular
                              style="margin-top: 35%"
                              v-if="isLoadingNotificationList === true"
                              indeterminate
                            ></v-progress-circular>
                            <v-list-item
                              v-if="isLoadingNotificationList == false"
                              style="width: 30vw; min-width: 320px"
                              :style="{
                                fontWeight:
                                  item.t_ba_notif_readed == true ? '100' : 'bold',
                              }"
                              v-for="(item, index) in notificationList"
                              :key="index"
                              :value="index"
                            >
                              <v-row
                                no-gutters
                                justify="space-between"
                                align-content="center"
                                style="margin-right: 10px"
                              >
                                <v-label
                                  style="margin-right=10px;"
                                  @click="openNotification(item.t_ba_notif_id)"
                                  >{{ item.t_ba_notif_title }}</v-label
                                >
                                <v-icon @click="removeNotification(item.t_ba_notif_id)"
                                  >mdi-close</v-icon
                                >
                              </v-row>
                            </v-list-item>
                          </v-container>

                          <v-row
                            style="margin-top: 3px; padding-right: 10px"
                            no-gutters
                            justify="end"
                            align-content="center"
                          >
                            <v-btn @click="clearNotification()"> Clear </v-btn>
                          </v-row>
                        </v-container>
                      </v-menu>
                      <v-label id="user-name">Hi, {{ profile.m_user_name }}</v-label>
                    </v-col>
                  </LoadingSkeleton>
                </v-col>
                <v-col id="use-image" cols="3" md="2" ms="2" lg="1" xl="1">
                  <img
                    style="width: 40px"
                    :src="profile.m_user_image ?? '/assets/user-avatar-2.png'"
                    alt=""
                  />
                </v-col>
                <v-menu activator="#use-image">
                  <v-list>
                    <v-list-item
                      @click="logout"
                      style="min-width: 20vw; min-width: 200px"
                    >
                      <v-label style="margin-right=10px;">Logout</v-label>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col id="content-body" cols="12">
          <slot />
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="isNotifDetailOpened" style="width: 60%; height: 60%">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.notificationDetail.t_ba_notif_title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="isNotifDetailOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container style="height: 70vh; text-align: left">
          <v-progress-circular
            style="margin-top: 35%"
            v-if="isLoadingNotificationDetail === true"
            indeterminate
          ></v-progress-circular>
          <div v-html="this.notificationDetail.t_ba_notif_content"></div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isNotifDetailOpened = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

export default {
  name: "MainLayout",
  props: {
    menuId: String,
  },
  components: {
    LoadingSkeleton,
  },
  data() {
    return {
      isNotifOpened: false,
      isNotifDetailOpened: false,
      isLoadingNotification: false,
      isLoadingNotificationList: false,
      isLoadingNotificationDetail: false,
      isLoadingSearch: false,
      notificationDetail: {},
      totalNotification: null,
      reloaded: false,
      currentdate: new Date(),
      isLoading: false,
      searchKey: "",
      profile: {},
      monthNames: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      dayhNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
      menus: [
        {
          id: 1,
          title: "Home",
          url: "/home",
          icon: "icon-menu-home.png",
          authorize: false,
          authorizeStatus: true,
          isLoading: false,
        },
        {
          id: 2,
          title: "Business Approval",
          url: "/approval/all",
          icon: "icon-menu-approval.png",
          authorize: false,
          authorizeStatus: true,
          isLoading: false,
        },
        {
          id: 3,
          title: "Draft",
          url: "/draft",
          icon: "icon-menu-draft.png",
          authorize: false,
          authorizeStatus: true,
          isLoading: false,
        },
        {
          id: 4,
          title: "Users",
          url: "/users",
          size: "25px",
          icon: "icon_menu_user.jpg",
          group: "MASTER",
          code: "USERS",
          authorize: true,
          authorizeStatus: false,
          isLoading: true,
        },
      ],
      notificationList: [],
    };
  },
  methods: {
    gotoUrl: function (url) {
      this.$router.push(url).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          this.$router.back();
        }
      });
    },
    startClock: function () {
      setTimeout(() => {
        this.currentdate = new Date();
        this.startClock();
      }, 1000);
    },
    onClickSearch: function (number) {
      this.isLoadingSearch = true;
      try {
        axios
          .get("ba/get-ba-trx-by-number", {
            params: {
              no: number,
            },
          })
          .then((result) => {
            this.$router.push("/detail-ba/" + result.data.t_ba_trx_id).catch((err) => {
              location.reload();
            });
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            this.$notify({
              group: "foo",
              title: "Error load BA TRX",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoadingNotification = false;
        this.$notify({
          group: "foo",
          title: "Error load counter inboxBA",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    addTokenToHeader: function (token) {
      axios.interceptors.request.use(
        (config) => {
          if (token) {
            config.headers.Authorization = `Bearer ${this.$cookie.get("token")}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    },
    getProfile: function () {
      this.isLoading = true;
      try {
        axios
          .get("auth/profile", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoading = false;
            this.profile = result.data;
          })
          .catch((err) => {
            this.$notify({
              group: "foo",
              title: "Error load User Profile",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.$notify({
          group: "foo",
          title: "Error load User Profile",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    getNotification: function () {
      this.isLoadingNotification = true;
      try {
        axios
          .get("dashboard/notification", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoadingNotification = false;
            this.totalNotification = result.data.total;
          })
          .catch((err) => {
            this.isLoadingNotification = false;
            this.$notify({
              group: "foo",
              title: "Error load counter inbox",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoadingNotification = false;
        this.$notify({
          group: "foo",
          title: "Error load counter inboxBA",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    getNotificationList: function () {
      this.isLoadingNotificationList = true;
      try {
        axios
          .get("notification/notificationList", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoadingNotificationList = false;
            this.notificationList = result.data;
          })
          .catch((err) => {
            this.isLoadingNotification = false;
            this.$notify({
              group: "foo",
              title: "Error load notification list",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoadingNotificationList = false;
        this.$notify({
          group: "foo",
          title: "Error load notification list",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    openNotification: function (notifId) {
      this.isNotifDetailOpened = true;
      this.isLoadingNotificationDetail = true;
      try {
        axios
          .get("notification/openNotification", {
            params: {
              id: notifId,
            },
          })
          .then((result) => {
            this.isLoadingNotificationDetail = false;
            this.notificationDetail = result.data;
            this.getNotification();
          })
          .catch((err) => {
            this.isLoadingNotification = false;
            this.$notify({
              group: "foo",
              title: "Error load notification detail",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoadingNotificationDetail = false;
        this.$notify({
          group: "foo",
          title: "Error load notification detail",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    removeNotification: function (id) {
      // Menghapus item 'orange' dari array
      try {
        axios
          .get("notification/removeNotification", {
            params: {
              id: id,
            },
          })
          .then((result) => {
            this.isLoadingNotificationDetail = false;
            this.notificationList.forEach((notif, index) => {
              if (notif.t_ba_notif_id === id) {
                this.notificationList.splice(index, 1);
              }
            });
          })
          .catch((err) => {
            this.$notify({
              group: "foo",
              title: "Error remove notification",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.$notify({
          group: "foo",
          title: "Error remove notification",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    clearNotification: function () {
      // Menghapus item 'orange' dari array
      this.isNotifOpened = false;
      this.$notify({
        group: "foo",
        title: "Start clear notification",
        text: "",
        duration: 50000, // optional, default to 3000,
        type: "warn", // optional, values are success, info, warn, error.
      });
      try {
        axios
          .get("notification/clearNotification")
          .then((result) => {
            this.isLoadingNotificationDetail = false;
            this.getNotification();
            this.$notify({
              group: "foo",
              title: "Success clear notification",
              duration: 50000, // optional, default to 3000,
              type: "success", // optional, values are success, info, warn, error.
            });
          })
          .catch((err) => {
            this.$notify({
              group: "foo",
              title: "Error clear notification",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.$notify({
          group: "foo",
          title: "Error clear notification",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    logout: function () {
      this.isLoading = true;
      try {
        axios
          .get("auth/logout", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoading = false;
            this.$cookie.delete("token");
            this.$router.replace({ path: "/", query: {} });
          })
          .catch((err) => {
            this.$notify({
              group: "foo",
              title: "Error on logout user",
              text: error.response.data.message ?? error,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.$notify({
          group: "foo",
          title: "Error on logout user",
          text: err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    checkMenuAuthorize: function () {
      for (let index = 0; index < this.menus.length; index++) {
        if (this.menus[index].authorize == true) {
          this.menus[index].isLoading = true;
          try {
            axios
              .get("flow/check-access-role", {
                params: {
                  group: this.menus[index].group,
                  code: this.menus[index].code,
                  action: "VIEW",
                },
              })
              .then((result) => {
                this.menus[index].isLoading = false;
                this.menus[index].authorizeStatus = true;
              })
              .catch((err) => {
                this.menus[index].isLoading = false;
                this.menus[index].authorizeStatus = false;
              });
          } catch (err) {
            this.$notify({
              group: "foo",
              title: "Error load authorize menu " + menu.name,
              text: err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          }
        }
      }
    },
  },
  computed: {
    dateTimeStr() {
      return (
        this.dayhNames[this.currentdate.getDay()] +
        ", " +
        this.currentdate.getDate() +
        " " +
        this.monthNames[this.currentdate.getMonth()] +
        " " +
        this.currentdate.getFullYear() +
        " | " +
        this.currentdate.getHours() +
        ":" +
        this.currentdate.getMinutes() +
        ":" +
        this.currentdate.getSeconds()
      );
    },
  },
  created() {
    this.startClock();
  },
  beforeMount() {},
  mounted() {
    this.addTokenToHeader(this.$cookie.get("token"));
    this.getProfile();
    this.getNotification();
    this.checkMenuAuthorize();
  },
  watch: {
    isNotifOpened: function (val) {
      if (val == true) {
        this.getNotificationList();
      }
    },
  },
};
</script>

<style>
#main-page {
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: cover;
  background-repeat: repeat;
  padding: 0px;
  margin: 0px;
}

#nav-body {
  height: 100%;
  width: 100%;
  background: rgba(0, 75, 68, 0.03);
}

#content-body {
  height: 87vh;
  width: 100%;
}

#nav-bar {
  background-color: white;
}

#logo-container {
  text-align: initial;
  padding-left: 20px;
}

#logo {
  /* Logo-Rhea---New 1 */
  margin-top: 40px;
  width: 175px;
}

.main-menu-item.active {
  /* Rectangle 315 */

  border-right-color: #004b44;
  border-right-style: solid;
  border-right-width: 3px;
  background: #f3f3f3;
}

.menu-title {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  color: #000000;
}

#user-name {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

#nav-header {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.main-menu-item-row {
  padding-left: 10px;
}

#clock {
  font-family: "Product Sans Light";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;

  color: #000000;
}

.col-clock {
  text-align: start;
}

#logo {
  margin-top: 40px;
}

.v-menu__content {
  background: #ffffff !important;
}

@media only screen and (min-width: 601px) {
  #nav-bar {
    height: 100%;
  }

  #content-body {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 601px) {
  .main-menu-item.active {
    /* Rectangle 315 */

    border-right-color: transparent;
    border-bottom-color: #004b44;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    background: #f3f3f3;
  }

  .menu-title {
    text-align: center;
    padding-top: 0px;
  }

  #user-info {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  #user-info-col {
    text-align: end;
    align-self: center;
    padding-bottom: 22px;
    padding-right: 0px;
  }

  #user-image {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-menu-item-row {
    padding-left: 0px;
  }

  #content-body {
    height: 63vh;
    width: 100%;
  }

  .col-clock {
    text-align: center;
  }

  #logo {
    /* Logo-Rhea---New 1 */
    margin-top: 80px;
    width: 175px;
  }
}
</style>
