import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import axios from 'axios';
import Notifications from 'vue-notification'
import './registerServiceWorker'



var VueCookie = require('vue-cookie');
Vue.use(VueCookie);
Vue.use(Notifications)



axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

axios.interceptors.response.use(
  response => response,
  error => {
    console.log("pesan dari interceptor");
    console.log(error.message);
    if (error.message === 'Network Error') {
      error.response = error.response ?? {};
      error.response.data = error.response.data ?? {};
      error.response.data.message = "Tidak dapat terhubung ke server, periksa jaringan internet anda";
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      error.response = error.response ?? {};
      error.response.data = error.response.data ?? {};
      error.response.data.message = "Sesi habis, silahkan login kembali";
      // tambahkan kode untuk menangani status 401 Unauthorized di sini
      VueCookie.delete("token");
      router.replace({ path: "/", query: {} });
    }

    if (error.response.status === 500) {
      error.response = error.response ?? {};
      error.response.data = error.response.data ?? {};
      error.response.data.message = error.response.data.message ?? "Opps. terjadi kesalahan, hubungi admin!";
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js')
      .then(function(registration) {
        console.log('Service worker registered:', registration);
      })
      .catch(function(error) {
        console.log('Service worker registration failed:', error);
      });
  });
}

new Vue({
  axios,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
