<template>
  <v-container
    id="main-container"
    :style="{ background: backgroundColor ?? '#bde7e3', color: 'white' }"
  >
    <v-row id="main-container-row">
      <v-col id="value" cols="12" align-self="end">
        <label style="color: white !important">{{ value ?? "0" }}</label>
      </v-col>
      <v-col style="height: 100%" id="label" cols="12" align-self="start">
        <label :style="{ color: 'white' }">{{ label ?? "Label" }}</label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardDatas",
  props: {
    value: String,
    label: String,
    color: String,
    backgroundColor: String,
  },
};
</script>

<style>
#main-container {
  padding-top: 60%;
  position: relative;
  border-radius: 15px;
  line-height: 0;
}

#main-container-row {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  padding-left: 15px;
  padding-top: 25px;
  padding-right: 10px;
}

#main-container-row .col {
  text-align: start;
}

#value label {
  font-family: "Product Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 18px;
  /* color: #000000; */
}

#label label {
  font-family: "Product Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* color: #000000; */
  white-space: pre-wrap;
}

/* .v-application {
  font-family: "Roboto", sans-serif;
  line-height: 0;
} */
</style>
