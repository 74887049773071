<template>
  <div class="home">
    <MainLayout menuId="1">
      <v-container style="padding: 20px; margin: 0px">
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="4" sm="5">
            <CardMenuHorizontal
              v-on:click="onClickCreateBa"
              iconMdi="mdi-plus"
              backgroundColor="004b44"
              menuLabel="Buat Bussines Approval"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="4" sm="5">
            <CardMenuHorizontal
              :isLoading="isLoadingInbox"
              v-on:click="onClickNeedApproval"
              iconImage="assets/menu-icon-check.png"
              :menuLabel="'Butuh \n Persetujuan'"
              backgroundColor="004b44"
              :badgeCount="this.totalInbox.toString()"
            />
          </v-col>
        </v-row>
        <v-row v-if="isLoading">
          <v-col cols="12" xl="3" lg="3" md="4" sm="5">
            <LoadingSkeleton style="min-height: 150px" :isLoading="true">
              <CardDatas value="50" label="Total BA" backgroundColor="#BDE7E3" />
            </LoadingSkeleton>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="4" sm="5">
            <LoadingSkeleton style="min-height: 150px" :isLoading="true">
              <CardDatas value="50" label="Total BA" backgroundColor="#BDE7E3" />
            </LoadingSkeleton>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="5" sm="5">
            <LoadingSkeleton style="min-height: 150px" :isLoading="true">
              <CardDatas value="50" label="Total BA" backgroundColor="#BDE7E3" />
            </LoadingSkeleton>
          </v-col>
        </v-row>
        <v-row v-if="isLoading == false">
          <v-col
            v-for="counter in this.counters"
            v-bind:key="counter.m_ref_id"
            cols="12"
            xl="3"
            lg="3"
            md="5"
            sm="5"
          >
            <CardDatas
              :value="counter.total.toString()"
              :label="counter.m_ref_name"
              :backgroundColor="counter.m_ref_background_color"
            />
          </v-col>
        </v-row>
      </v-container>
    </MainLayout>
  </div>
</template>

<script>
// @ is an alias to /src
import MainLayout from "@/components/MainLayout.vue";
import CardMenuHorizontal from "@/components/CardMenuHorizontal.vue";
import CardDatas from "@/components/CardDatas.vue";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    MainLayout,
    CardMenuHorizontal,
    CardDatas,
    LoadingSkeleton,
  },
  data() {
    return {
      isLoading: true,
      isLoadingInbox: true,
      counters: [],
      totalInbox: 0,
    };
  },
  methods: {
    onClickCreateBa: function () {
      this.$router.push("/select-ba-type").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          return;
        }
      });
    },
    onClickNeedApproval: function () {
      this.$router
        .push({ path: "/approval/inbox", query: { status: "needApproval" } })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            return;
          }
        });
    },
    getCounter: function () {
      this.isLoading = true;
      try {
        axios
          .get("dashboard/counter", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoading = false;
            this.counters = result.data;
          })
          .catch((err) => {
            this.isLoading = false;
            this.$notify({
              group: "foo",
              title: "Error load counter",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoading = false;
        this.$notify({
          group: "foo",
          title: "Error load counter",
          text: err.response.data.message ?? err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
    getInbox: function () {
      this.isLoadingInbox = true;
      try {
        axios
          .get("dashboard/inbox", {
            params: {
              id: this.$route.params.id,
            },
          })
          .then((result) => {
            this.isLoadingInbox = false;
            this.totalInbox = result.data.total;
          })
          .catch((err) => {
            this.isLoadingInbox = false;
            this.$notify({
              group: "foo",
              title: "Error load counter inbox",
              text: err.response.data.message ?? err,
              duration: 50000, // optional, default to 3000,
              type: "error", // optional, values are success, info, warn, error.
            });
          });
      } catch (err) {
        this.isLoadingInbox = false;
        this.$notify({
          group: "foo",
          title: "Error load counter inboxBA",
          text: err.response.data.message ?? err,
          duration: 50000, // optional, default to 3000,
          type: "error", // optional, values are success, info, warn, error.
        });
      }
    },
  },
  mounted() {
    this.getCounter();
    this.getInbox();
  },
};
</script>
<style></style>
