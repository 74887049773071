<template>
  <div :class="{ skeletonLoading: isLoading }">
    <slot v-if="isLoading == false"/>
  </div>
</template>

<script>
export default {
  name: "LoadingSkeleton",
  props: {
    isLoading: {
      type: Boolean,
      default: true, // default value
    },
  },
};
</script>

<style>
.skeletonLoading {
  position: relative;
  min-height: 50px;
  width: 100%;
  background-color: #ddd;
  animation: skeletonLoading 1.5s infinite ease-in-out;
}

@keyframes skeletonLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
